import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { HoldemData } from "./HoldemData";

const Holdem = () => {
  // const navigate = useNavigate();
  // const { isAuth } = useSelector((state) => state.auth);
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className="tv_shows_slider casino_games live_casino">
      <Row>
        <Col xs={12}>
          <div className="d-xl-flex">
            <div className="games">
              {/* <h5>Holdem</h5> */}
              <Slider {...settings}>
                {HoldemData.map((slide) => (
                  <div>
                    <div className="game-box" key={slide.a}>
                      <div className="img-box">
                        <div className="overlay">
                          <a href={slide.link} className="play-btn">
                            {slide.a}
                          </a>
                        </div>
                        <img src={slide.image} alt="{slide.alt}" />
                      </div>
                      <div className="game_name">{slide.gameName}</div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Holdem;
