import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { APP_CONST } from "../config/const";

import GamesIcon from "../assets/svg/GamesIcon";
import SportsIcon from "../assets/svg/SportsIcon";

import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";

export default function BottomNav() {
  const { isAuth } = useSelector((state) => state.auth);
  const [activePage, setActivePage] = useState("");
  const location = useLocation();
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });
  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };
  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const footerPages = ["qtech", "betby"];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            {isAuth ? (
              <a
                className={activePage === "qtech" ? "active" : ""}
                href="/casino/qtech"
              >
                <GamesIcon />
                <span>Casino</span>
              </a>
            ) : (
              <a onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}>
                <GamesIcon />
                <span>Casino</span>
              </a>
            )}
          </li>
          <li>
            {isAuth ? (
              <a
                className={activePage === "betby" ? "active" : ""}
                href="/betby"
              >
                <SportsIcon />
                <span>Sports</span>
              </a>
            ) : (
              <a className="active" href="javascript:void(0)">
                <SportsIcon />
                <span>Sports</span>
              </a>
            )}
          </li>
        </ul>
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberModal
        show={showAuthModals.mobileNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberVerifyModal
        show={showAuthModals.verifyNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <RegisterModal
        show={showAuthModals.register}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
        handleModalShow={handleModalShow}
      />
    </>
  );
}
